import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Artigo from '../artigo';
import Container from '../container';
import Row from '../row';
import Col from '../col';
import api from '../../api';
import './blog.scss';

class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      link: []
    };
  }

  componentDidMount() {
    api.get('/link').then(res => {
      this.setState({
        link: res.data
      });
    });
  }
  render() {
    return (
      <>
        <div className="blog" id="blog">
          <div className="subBlog">
            <div className="intBlog">
              <ScrollAnimation animateIn="fadeIn" delay={300}>
                <div className="titulo">
                  <div className="titulo">Blog</div>
                </div>
                <Container>
                  <Row>
                    <Col center>
                      <Artigo />
                    </Col>
                  </Row>
                </Container>
                <br />
                <a
                  href={this.state.link.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="subBotao" onClick={this.toggle}>
                    <button className="botao">Acesse</button>
                  </div>
                </a>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Blog;
