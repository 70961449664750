import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Container from '../container';
import Row from '../row';
import Col from '../col';
import Mapa from '../mapa';
import Input from '../form/input';
import Textarea from '../form/textarea';
import Buttom from '../button';
import api from '../../api';
import './contato.scss';

class Contato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target }) {
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { name, email, assunto, message } = this.state;

    if (name && email && assunto && message) {
      const params = new URLSearchParams();
      params.append('name', name);
      params.append('email', email);
      params.append('assunto', assunto);
      params.append('message', message);

      api
        .post('/contact', params)
        .then(({ data }) => {
          if (data.success) {
            alert('Mensagem enviada com sucesso!');
            this.setState({
              name: '',
              email: '',
              assunto: '',
              message: ''
            });
          } else {
            alert(data.message);
          }
        })
        .catch(e => console.error(e));
    }
  }

  render() {
    return (
      <div id="contato">
        <ScrollAnimation animateIn="fadeIn">
          <Container>
            <Row >
              <Col size={6}>
                <div className="contatin">
                  <h3 className="contact-title">Contato</h3>
                  <form onSubmit={this.handleSubmit}>
                    <Input
                      type="text"
                      placeholder="Nome"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    <Input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    <Input
                      type="text"
                      placeholder="Assunto"
                      name="assunto"
                      value={this.state.assunto}
                      onChange={this.handleChange}
                    />
                    <Textarea
                      rows="7"
                      placeholder="Descrição"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleChange}
                    />
                    <div className="enviar">
                      <Buttom>Enviar</Buttom>
                    </div>
                  </form>
                </div>
              </Col>
              <Col size={6}>
                <Mapa />
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    );
  }
}

export default Contato;
