import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Produtos from '../produtos';
import Container from '../container';
import Row from '../row';
import api from '../../api';
import './grade.scss';

class Grade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      gramatura: '',
      sabor: '',
      produtos: [],
      dataGramatura: [],
      dataProdutos: [],
      dataSabor: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  handleChange({ target }) {
    const { name, value } = target;
    let data = {};

    if (name === 'nome') {
      data = {
        nome: value,
        gramatura: '',
        sabor: ''
      };
    } else if (name === 'gramatura') {
      data = {
        gramatura: value,
        sabor: ''
      };
    } else {
      data = { sabor: value };
    }

    this.setState(data, this.getData);
  }

  getData() {
    const { nome, gramatura, sabor } = this.state;
    const url = `/produtos/?nome=${nome}&gramatura=${gramatura}&sabor=${sabor}`;
    api.get(url).then(res => {
      this.setState({
        produtos: res.data.produtos,
        dataGramatura: res.data.gramatura,
        dataProdutos: res.data.prod,
        dataSabor: res.data.sabor
      });
    });
  }

  render() {
    return (
      <>
        <ScrollAnimation animateIn="fadeIn" delay={300}>
          <Container>
            <Row>
              <div class="gradeInt">
                <div className="select" id="produtos">
                  <div className="p">Nossos produtos</div>
                  <br />
                  <select
                    name="nome"
                    onChange={this.handleChange}
                    value={this.state.nome}
                  >
                    <option value=""> Escolha o produto </option>
                    {this.state.dataProdutos.map(prod => (
                      <option value={prod.id}>{prod.nome}</option>
                    ))}
                  </select>
                  <select
                    name="gramatura"
                    onChange={this.handleChange}
                    value={this.state.gramatura}
                  >
                    <option value=""> Escolha a gramatura</option>
                    {this.state.dataGramatura.map(gram => (
                      <option value={gram.id}>{gram.nome}</option>
                    ))}
                  </select>
                  <select
                    name="sabor"
                    onChange={this.handleChange}
                    value={this.state.sabor}
                  >
                    <option value="">Escolha o sabor</option>
                    {this.state.dataSabor.map(sabor => (
                      <option value={sabor.id}>{sabor.nome}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <Produtos produtos={this.state.produtos} />
                </div>
              </div>
            </Row>
          </Container>
        </ScrollAnimation>
      </>
    );
  }
}

export default Grade;
