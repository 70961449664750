import React, { Component } from 'react';
import Slider from 'react-slick';
import ScrollToElement from 'react-scroll-to-element';
import Row from '../row';
import Col from '../col';
import api from '../../api';
import './header.scss';

const settings = {
  arrows: false,
  speed: 1000,
  autoplaySpeed: 5000,
  autoplay: true
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banners: [],
      social: []
    };
  }

  hover() {
    var adcmenu = document.querySelector('.ulmobile');
    adcmenu.classList.toggle('menumobile');
  }

  componentDidMount() {
    api.get('/banners').then(res => {
      this.setState({
        banners: res.data
      });
    });
    api.get('/social').then(res => {
      this.setState({
        social: res.data
      });
    });
  }
  render() {
    window.onscroll = function() {
      var top = window.pageYOffset || document.documentElement.scrollTop;
      if (top > 0) {
        var elementA = document.querySelector('.header');
        elementA.classList.add('fixo');
        var imgA = document.querySelector('.img');
        imgA.classList.add('fixoImg');
        var iconF = document.querySelector('.face');
        iconF.classList.add('fixoIcon');
        var iconI = document.querySelector('.insta');
        iconI.classList.add('fixoIcon');
        var botao = document.querySelector('.headerMobile');
        botao.classList.add('fixoMobile');
      } else if (top < 1) {
        var elementR = document.querySelector('.header');
        elementR.classList.remove('fixo');
        var imgR = document.querySelector('.img');
        imgR.classList.add('img');
        imgR.classList.remove('fixoImg');
        var iconRF = document.querySelector('.face');
        iconRF.classList.remove('fixoIcon');
        var iconR = document.querySelector('.insta');
        iconR.classList.remove('fixoIcon');
        var botaoR = document.querySelector('.headerMobile');
        botaoR.classList.remove('fixoMobile');
      }
    };
    return (
      <>
        <Row>
          <div
            className="header"
            itemscope
            itemtype="https://schema.org/LocalBusiness"
          >
            <Col>
              <div itemprop="logo" className="img" />
            </Col>
            <Col center>
              <div className="ul">
                <ul>
                  <ScrollToElement
                    type="id"
                    element="home"
                    offset={-150}
                    duration={800}
                  >
                    <li>
                      <span>Home</span>
                    </li>
                  </ScrollToElement>
                  <ScrollToElement
                    type="id"
                    element="brejeira"
                    offset={-150}
                    duration={800}
                  >
                    <li>
                      <span itemprop="name">A Brejeira</span>
                    </li>
                  </ScrollToElement>
                  <ScrollToElement
                    type="id"
                    element="produtos"
                    offset={-200}
                    duration={800}
                  >
                    <li>
                      <span>Produtos</span>
                    </li>
                  </ScrollToElement>
                  <ScrollToElement
                    type="id"
                    element="blog"
                    offset={-30}
                    duration={800}
                  >
                    <li>
                      <span>Blog</span>
                    </li>
                  </ScrollToElement>
                  <ScrollToElement
                    type="id"
                    element="brasilmap"
                    offset={-150}
                    duration={800}
                  >
                    <li>
                      <span>Nos encontre</span>
                    </li>
                  </ScrollToElement>
                  <ScrollToElement
                    type="id"
                    element="contato"
                    offset={-150}
                    duration={800}
                  >
                    <li>
                      <span>Contato</span>
                    </li>
                  </ScrollToElement>
                </ul>
              </div>
              <div className="headerMobile" onClick={this.hover}>
                <div className="ulmobile">
                  <ul>
                    <ScrollToElement
                      type="id"
                      element="home"
                      offset={-150}
                      duration={800}
                    >
                      <li>
                        <span>Home</span>
                      </li>
                    </ScrollToElement>
                    <ScrollToElement
                      type="id"
                      element="brejeira"
                      offset={-150}
                      duration={800}
                    >
                      <li>
                        <span>A Brejeira</span>
                      </li>
                    </ScrollToElement>
                    <ScrollToElement
                      type="id"
                      element="produtos"
                      offset={-200}
                      duration={800}
                    >
                      <li>
                        <span>Produtos</span>
                      </li>
                    </ScrollToElement>
                    <ScrollToElement
                      type="id"
                      element="blog"
                      offset={-30}
                      duration={800}
                    >
                      <li>
                        <span>Blog</span>
                      </li>
                    </ScrollToElement>
                    <ScrollToElement
                      type="id"
                      element="brasilmap"
                      offset={-150}
                      duration={800}
                    >
                      <li>
                        <span>Nos encontre</span>
                      </li>
                    </ScrollToElement>
                    <ScrollToElement
                      type="id"
                      element="contato"
                      offset={-150}
                      duration={800}
                    >
                      <li>
                        <span>Contato</span>
                      </li>
                    </ScrollToElement>
                  </ul>
                </div>
              </div>
            </Col>
            <Col>
              <div className="social">
                {this.state.social.facebook !== '' ? (
                  <a
                    href={`https://facebook.com/${this.state.social.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="20px"
                      height="20px"
                      className="icon face"
                    >
                      <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z" />
                    </svg>
                  </a>
                ) : (
                  ''
                )}
                {this.state.social.instagram !== '' ? (
                  <a
                    href={`https://instagram.com/${
                      this.state.social.instagram
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="22px"
                      height="22px"
                      className="icon insta"
                    >
                      <path d="M 11.46875 5 C 7.917969 5 5 7.914063 5 11.46875 L 5 20.53125 C 5 24.082031 7.914063 27 11.46875 27 L 20.53125 27 C 24.082031 27 27 24.085938 27 20.53125 L 27 11.46875 C 27 7.917969 24.085938 5 20.53125 5 Z M 11.46875 7 L 20.53125 7 C 23.003906 7 25 8.996094 25 11.46875 L 25 20.53125 C 25 23.003906 23.003906 25 20.53125 25 L 11.46875 25 C 8.996094 25 7 23.003906 7 20.53125 L 7 11.46875 C 7 8.996094 8.996094 7 11.46875 7 Z M 21.90625 9.1875 C 21.402344 9.1875 21 9.589844 21 10.09375 C 21 10.597656 21.402344 11 21.90625 11 C 22.410156 11 22.8125 10.597656 22.8125 10.09375 C 22.8125 9.589844 22.410156 9.1875 21.90625 9.1875 Z M 16 10 C 12.699219 10 10 12.699219 10 16 C 10 19.300781 12.699219 22 16 22 C 19.300781 22 22 19.300781 22 16 C 22 12.699219 19.300781 10 16 10 Z M 16 12 C 18.222656 12 20 13.777344 20 16 C 20 18.222656 18.222656 20 16 20 C 13.777344 20 12 18.222656 12 16 C 12 13.777344 13.777344 12 16 12 Z " />
                    </svg>
                  </a>
                ) : (
                  ''
                )}
              </div>
            </Col>
          </div>
        </Row>
        <div className="banner" id="home">
          <div className="banner-bg">
            <Slider {...settings}>
              {this.state.banners.map(banners => (
                <img src={banners.imagem} className="imgBanner" alt="banner" />
              ))}
            </Slider>
          </div>
          <div className="banner-text">
            <div>
              <div itemprop="slogan">
                <p className="textindobanner">Paixão pelo sabor da gente.</p>
              </div>
              <ScrollToElement
                type="id"
                element="brejeira"
                offset={-150}
                duration={800}
              >
                <div className="subBot">
                  <button className="bot">Conheça a Brejeira</button>
                </div>
              </ScrollToElement>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Header;
