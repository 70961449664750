import React, { Component } from 'react';
import Slider from 'react-slick';
import './produtos.scss';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false
      }
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false
      }
    }
  ]
};

class Produtos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true
    };
  }
  render() {
    return (
      <div className="produtos">
        {this.state.loaded ? (
          <div className="slide">
            <Slider {...settings}>
              {this.props.produtos.map(produto => (
                <div className="scale" style={{ textAlign: 'center' }}>
                  <div className="div1">
                    <img
                      src={produto.imagem}
                      alt={produto.produto}
                      className="image"
                    />
                    <h3>
                      {produto.produto}
                      <br />
                      {produto.sabor}
                    </h3>
                    <h5>{produto.gramatura}</h5>
                    <div className="div2" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <h3>Produto não Disponivel</h3>
        )}
      </div>
    );
  }
}

export default Produtos;
