import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import renderHTML from 'react-render-html';
import Container from '../row';
import Row from '../row';
import Col from '../col';
import api from '../../api';
import './sobre.scss';

class Sobre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMore: false,
      brejeira: {
        texto_maior: '',
        texto_menor: ''
      }
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      viewMore: !prevState.viewMore
    }));
  }

  componentDidMount() {
    api.get('/brejeira').then(res => {
      this.setState({
        brejeira: res.data
      });
    });
  }

  render() {
    return (
      <div className="about">
        <div
          className="about-content"
          id="brejeira"
          style={{ textAlign: 'center' }}
        >
          <div className="about-center">
            <Container>
              <Row>
                <Col size={6}>
                  <ScrollAnimation animateIn="fadeInLeft">
                    <img src={this.state.brejeira.img_maior} alt="A Brejeira" />
                    <h1 className="text">{this.state.brejeira.texto_centro}</h1>
                  </ScrollAnimation>
                </Col>
                <Col size={6}>
                  <ScrollAnimation animateIn="fadeInRight">
                    <p itemprop="description">
                      {this.state.viewMore
                        ? renderHTML(this.state.brejeira.texto_maior)
                        : renderHTML(this.state.brejeira.texto_menor)}
                    </p>
                    <br />
                    <div className="subButao" onClick={this.toggle}>
                      <button className="butao">
                        {this.state.viewMore ? 'Leia Menos' : 'Leia Mais'}
                      </button>
                    </div>
                    <br />
                    <div>
                      {this.state.viewMore ? (
                        ''
                      ) : (
                        <img
                          className="imgLeft"
                          src={this.state.brejeira.img_menor}
                          alt="img"
                          width="400px"
                        />
                      )}
                    </div>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default Sobre;
