import React, { Component } from 'react';
import Slider from 'react-slick';
import api from '../../api';
import './artigo.scss';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    }
  ]
};

class Artigo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: []
    };
  }
  componentDidMount() {
    api.get('/blog').then(res => {
      this.setState({
        blog: res.data
      });
    });
  }
  render() {
    return (
      <>
        <div className="artigos">
          <Slider {...settings}>
            {this.state.blog.map(artigo => (
              <a
                href={artigo.link}
                rel="noopener noreferrer"
                target="_blank"
                className="azin"
              >
                <div className="not">
                  <img className="imagem" src={artigo.imagem} alt="img-blog" />
                  <div className="textinho">
                    <h2>{artigo.texto}</h2>
                    <h6>{artigo.data}</h6>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}

export default Artigo;
