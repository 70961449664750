import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';
import ScrollAnimation from 'react-animate-on-scroll';

const Map = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBA6CwrTEhAZQNmzQzraQ8g7k1rMUzRQ3I',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="mapinhashow" />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <>
    <ScrollAnimation animateIn="fadeIn">
      <div className="mapabrejeira">
        <GoogleMap
          defaultZoom={16}
          defaultCenter={{ lat: -7.820261, lng: -38.15175 }}
        >
          <Marker position={{ lat: -7.820261, lng: -38.15175 }} />
        </GoogleMap>
      </div>
    </ScrollAnimation>
  </>
));

export default Map;
