import React, { Component } from 'react';
import Grade from '../grade';
import Sobre from '../sobre';
import Blog from '../blog';
import Brasil from '../brasil';
import Section from '../section';
import Contato from '../contato';
import api from '../../api';
import './main.scss';

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      canChange: true,
      mapa: {
        latitude: '',
        longitude: ''
      }
    };

    this.changeGest = this.changeGest.bind(this);
    this.autoPlay = this.autoPlay.bind(this);
    this.timeOut = null;
  }

  componentDidMount() {
    api.get('/mapa').then(res => {
      this.setState({
        mapa: {
          latitude: parseFloat(res.data.latitude),
          longitude: parseFloat(res.data.longitude)
        }
      });
    });
  }

  autoPlay() {
    if (this.state.canChange) {
      this.timeOut = setTimeout(() => {
        this.setState(state => ({
          current: (state.current + 1) % this.state.info.length
        }));
      }, 2000);
    } else {
      clearTimeout(this.timeOut);
    }
  }

  changeGest(id) {
    this.setState({ current: id, canChange: false });
  }

  render() {
    return (
      <main className="main">
        <Section>
          <Sobre />
          <Grade />
        </Section>
        <Section>
          <Blog />
          <Brasil />
        </Section>
        <Section>
          <Contato />
        </Section>
      </main>
    );
  }
}

export default Main;
