import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from '../components/header';
import Main from '../components/main';
import Footer from '../components/footer';

const HomePage = () => (
  <>
    <Header />
    <Main />
    <Footer />
  </>
);

const notFoundScreen = () => (
  <>
    <div>
      <h1>teste</h1>
    </div>
  </>
);

const App = () => (
  <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route component={notFoundScreen} />
      </Switch>
    </BrowserRouter>
  </>
);

export default App;
