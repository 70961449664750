import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Col = props => (
  <div className={`col col-${props.size}`} style={props.style}>
    {props.children}
  </div>
);

Col.defaultProps = {
  size: 12,
  style: {}
};

Col.propTypes = {
  size: PropTypes.oneOf([12, 6, 4]),
  children: PropTypes.node,
  style: PropTypes.object
};

export default Col;
