import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Row = props => (
  <div
    className={`row${props.reverse ? ' reverse' : ''}${
      props.full ? ' full' : ''
    }${props.center ? ' center' : ''}`}
    style={props.style}
  >
    {props.children}
  </div>
);

Row.defaultProps = {
  full: false,
  center: false,
  reverse: false,
  style: {}
};

Row.propTypes = {
  full: PropTypes.bool,
  center: PropTypes.bool,
  reverse: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object
};

export default Row;
